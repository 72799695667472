import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, observable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { RawWorkflowDefinition } from './model/workflow/WorkflowDefinitionModel'
import WorkflowDefinitionModel from './model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowStepDefinition } from './model/workflow/WorkflowStepDefinitionModel'
import WorkflowStepDefinitionModel from './model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from './model/workflow/WorkflowTriggerDefinitionModel'
import WorkflowTriggerDefinitionModel from './model/workflow/WorkflowTriggerDefinitionModel'
import {
  WORKFLOW_DEFINITION_TABLE_NAME,
  type WorkflowDefinitionRepository,
} from './worker/repository/WorkflowDefinitionRepository'
import type { WorkflowStepDefinitionRepository } from './worker/repository/WorkflowStepDefinitionRepository'
import { WORKFLOW_STEP_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowStepDefinitionRepository'
import type { WorkflowTriggerDefinitionRepository } from './worker/repository/WorkflowTriggerDefinitionRepository'
import { WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowTriggerDefinitionRepository'

export default class WorkflowStore {
  readonly definitionsCollection: PersistedCollection<
    WorkflowDefinitionModel,
    WorkflowDefinitionRepository
  >
  readonly stepDefinitionsCollection: PersistedCollection<
    WorkflowStepDefinitionModel,
    WorkflowStepDefinitionRepository
  >
  readonly triggerDefinitionsCollection: PersistedCollection<
    WorkflowTriggerDefinitionModel,
    WorkflowTriggerDefinitionRepository
  >

  // We keep track of the entity ids for which we have fetched definitions
  private readonly fetchedDefinitionsEntityIds = new Set<string>()
  private readonly fetchedStepDefinitionIds = new Set<string>()
  private readonly fetchedTriggerDefinitionIds = new Set<string>()

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.definitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowDefinition) =>
        new WorkflowDefinitionModel(json),
    })
    this.stepDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_STEP_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowStepDefinition) =>
        new WorkflowStepDefinitionModel(json),
    })
    this.triggerDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowTriggerDefinition) =>
        new WorkflowTriggerDefinitionModel(json),
    })

    makeAutoObservable<this>(this, {
      definitionsCollection: observable,
      stepDefinitionsCollection: observable,
      triggerDefinitionsCollection: observable,
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    this.load()
  }

  getDefinitionById(definitionId: string): WorkflowDefinitionModel | null {
    return this.definitionsCollection.get(definitionId)
  }

  getWorkflowStepById(
    workflowDefinitionId: string,
    stepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string] | null {
    const definition = this.getDefinitionById(workflowDefinitionId)

    const workflowStep = definition?.workflowSteps[stepId]

    return workflowStep ?? null
  }

  getDefinitionsForEntityId(entityId: string): WorkflowDefinitionModel[] {
    const localDefinitionsForEntityId = this.definitionsCollection.list.filter(
      (definition) => definition.entityId === entityId,
    )

    this.fetchDefinitionsForEntityIdIfNeeded(entityId, localDefinitionsForEntityId)

    return localDefinitionsForEntityId
  }

  getEnabledDefinitionForEntityId(entityId: string): WorkflowDefinitionModel | null {
    return (
      this.getDefinitionsForEntityId(entityId).find((definition) => definition.enabled) ??
      null
    )
  }

  getStepDefinitionById(stepDefinitionId: string): WorkflowStepDefinitionModel | null {
    const localStepDefinition = this.stepDefinitionsCollection.get(stepDefinitionId)

    this.fetchStepDefinitionFromRemoteIfNeeded(stepDefinitionId, localStepDefinition)

    return localStepDefinition ?? null
  }

  getTriggerDefinitionById(
    triggerDefinitionId: string,
  ): WorkflowTriggerDefinitionModel | null {
    const localTriggerDefinition =
      this.triggerDefinitionsCollection.get(triggerDefinitionId)

    this.fetchTriggerDefinitionFromRemoteIfNeeded(
      triggerDefinitionId,
      localTriggerDefinition,
    )

    return localTriggerDefinition ?? null
  }

  // TODO this will be removed
  postWorkflow(definition: RawWorkflowDefinition) {
    this.root.transport.workflow.definitions.post(definition).then((response) => {
      console.log(response)
    })
  }

  putWorkflow(definition: WorkflowDefinitionModel) {
    this.root.transport.workflow.definitions
      .put(definition.id, definition.serialize())
      .then((response) => {
        this.definitionsCollection.put(new WorkflowDefinitionModel(response))
      })
  }

  temp() {
    this.root.transport.workflow.stepDefinitions.list(1000).then((response) => {
      console.log(response)
    })
  }

  // TODO this will be removed
  resetWorkflow(definitionId: string) {
    this.root.transport.workflow.definitions.put(definitionId, {
      id: definitionId,
      version: 1,
      createdAt: '2021-09-01T00:00:00Z',
      createdBy: 'USRjowips9',
      orgId: 'ORGjowips9',
      enabled: true,
      trigger: {
        definitionId: 'WTDincomingCall',
      },
      entityId: 'PNsLC8GsIR',
      configuration: [
        {
          value: 'PNFrAhK10Q',
          variableKey: 'WVphoneNumberId',
        },
        {
          value: 'https://voicemail.url',
          variableKey: 'WVvoicemailUrl',
        },
        {
          value: 'RO123',
          variableKey: 'WVringOrderId',
        },
      ],
      initialStepId: 'WSbusinessHours',
      workflowSteps: {
        WSringUsers: {
          id: 'WSringUsers',
          branches: [],
          definitionId: 'WSDringUsers',
        },
        // WSjump: {
        //   id: 'WSjump',
        //   definitionId: 'WSDjump',
        //   branches: [
        //     {
        //       key: 'WBjump',
        //       nextStepId: 'WSvoicemail',
        //     },
        //   ],
        // },
        WSvoicemail: {
          id: 'WSvoicemail',
          branches: [],
          definitionId: 'WSDvoicemail',
        },
        WSbusinessHours: {
          id: 'WSbusinessHours',
          branches: [
            {
              key: 'WBduringHours',
              nextStepId: 'WSringUsers',
            },
            {
              key: 'WBafterHours',
              nextStepId: 'WSvoicemail',
            },
          ],
          definitionId: 'WSDbusinessHours',
        },
      },
    })
  }

  // TODO this will be removed
  resetWorkflow2(definitionId: string) {
    this.root.transport.workflow.definitions.put(definitionId, {
      orgId: 'ORGjowips9',
      entityId: 'PNsLC8GsIR',
      id: definitionId,
      version: 1,
      createdAt: '2024-09-11T06:15:39.306Z',
      createdBy: 'US6r1KcneA',
      enabled: true,
      trigger: { definitionId: 'WTDincomingMessage' },
      initialStepId: 'WSbusinessHours',
      workflowSteps: {
        WSsendMessage: {
          id: 'WSsendMessage',
          definitionId: 'WSDsendMessage',
          branches: [],
          configuration: [
            {
              variableKey: 'WVmessageBody',
              value:
                'Hi, we are currently closed for business for the day. We will get back to you first thing in the morning!',
            },
            { variableKey: 'WVtoPhoneNumber', value: { variableKey: 'WVfrom' } },
          ],
        },
        WSbusinessHours: {
          id: 'WSbusinessHours',
          definitionId: 'WSDbusinessHours',
          branches: [{ key: 'WBafterHours', nextStepId: 'WSsendMessage' }],
        },
      },
      updatedAt: '2024-09-11T06:15:39.306Z',
      updatedBy: 'US6r1KcneA',
      configuration: [{ variableKey: 'WVphoneNumberId', value: 'PNFrAhK10Q' }],
    })
  }

  addNode({
    definitionId,
    sourceId,
    targetId,
  }: {
    definitionId: string
    sourceId: string
    targetId: string | null
  }) {
    // TODO this is a placeholder implementation
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const id = 'WS' + Math.random().toString()

    const sourceStep = definition.workflowSteps[sourceId]

    // If the source is a step, we need to update the branch that points to the target.
    // If the source is a trigger, we don't need to do anything since there are no branches.
    if (sourceStep) {
      const branchToUpdateIndex =
        sourceStep.branches?.findIndex((branch) => branch.nextStepId === targetId) ?? -1
      const branchToUpdate = sourceStep.branches
        ? sourceStep.branches[branchToUpdateIndex]
        : null

      if (branchToUpdate) {
        branchToUpdate.nextStepId = id
      } else {
        sourceStep.branches = [
          ...(sourceStep.branches ?? []),
          {
            // TODO placeholder
            key: 'WB',
            nextStepId: id,
          },
        ]
      }
    }

    const newStep: DBTypes.WorkflowDefinitionRow['workflowSteps'][string] = {
      id,
      // TODO placeholder
      definitionId: 'WSDringUsers',
      definitionVersion: 1,
      branches: targetId
        ? [
            {
              // TODO placeholder
              key: 'WB',
              nextStepId: targetId,
            },
          ]
        : [],
    }

    const workflowSteps = {
      ...definition.workflowSteps,
      ...(sourceStep ? { [sourceStep.id]: sourceStep } : {}),
      [newStep.id]: newStep,
    }

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      // An undefined sourceStep means this new node was placed
      // immediately after the trigger and is now technically the
      // first step in the definition (because triggers aren't part
      // of definition steps). In this case, update the definition
      // initialStepId to this new step's id.
      // Otherwise, preserve the original value.
      initialStepId: sourceStep ? definition.initialStepId : newStep.id,
      workflowSteps,
    })

    this.definitionsCollection.put(updatedWorkflowDefinition)

    // TODO remove this put if you want to delay saving the changes
    this.putWorkflow(updatedWorkflowDefinition)
  }

  removeNode(definitionId: string, nodeId: string) {
    // TODO this is a placeholder implementation
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const workflowSteps = { ...definition.workflowSteps }

    delete workflowSteps[nodeId]

    Object.values(workflowSteps).forEach((step) => {
      step.branches = step.branches?.filter((branch) => branch.nextStepId !== nodeId)
    })

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      workflowSteps,
    })

    this.definitionsCollection.put(updatedWorkflowDefinition)

    // TODO remove this put if you want to delay saving the changes
    this.putWorkflow(updatedWorkflowDefinition)
  }

  private async fetchDefinitionsForEntityIdIfNeeded(
    entityId: string,
    localDefinitionsForEntityId: WorkflowDefinitionModel[],
  ) {
    // We want to fetch the remote definitions associated with that entity id at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedDefinitionsEntityIds.has(entityId)
    if (alreadyFetched) {
      return
    }

    const remoteDefinitionsForEntityId = await this.fetchDefinitionsForEntityId(entityId)
    this.fetchedDefinitionsEntityIds.add(entityId)

    // Remove the local entities that have been deleted in the remote
    localDefinitionsForEntityId.forEach((localDefinition) => {
      const remoteDefinition = remoteDefinitionsForEntityId.find(
        (remoteDefinitionForEntityId) =>
          remoteDefinitionForEntityId.id === localDefinition.id,
      )

      if (!remoteDefinition) {
        this.definitionsCollection.delete(localDefinition.id)
      }
    })

    remoteDefinitionsForEntityId.forEach((remoteDefinition) => {
      this.definitionsCollection.put(new WorkflowDefinitionModel(remoteDefinition))
    })
  }

  private async fetchStepDefinitionFromRemoteIfNeeded(
    stepDefinitionId: string,
    localStepDefinition: WorkflowStepDefinitionModel | null,
  ) {
    // We want to fetch the remote step definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedStepDefinitionIds.has(stepDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteStepDefinition = await this.fetchStepDefinitionById(stepDefinitionId)
    this.fetchedStepDefinitionIds.add(stepDefinitionId)

    if (localStepDefinition && !remoteStepDefinition) {
      // If the remote step definition has been deleted, delete the local one too
      this.stepDefinitionsCollection.delete(localStepDefinition.id)
    } else if (localStepDefinition && remoteStepDefinition) {
      // If both local and remote exist, update local step definition with remote data
      localStepDefinition.deserialize(remoteStepDefinition)
    } else if (!localStepDefinition && remoteStepDefinition) {
      // If the step definition exists only on remote, add it to the local collection
      this.stepDefinitionsCollection.put(
        new WorkflowStepDefinitionModel(remoteStepDefinition),
      )
    }
  }

  private async fetchTriggerDefinitionFromRemoteIfNeeded(
    triggerDefinitionId: string,
    localTriggerDefinition: WorkflowTriggerDefinitionModel | null,
  ) {
    // We want to fetch the remote trigger definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedTriggerDefinitionIds.has(triggerDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteTriggerDefinition =
      await this.fetchTriggerDefinitionById(triggerDefinitionId)
    this.fetchedTriggerDefinitionIds.add(triggerDefinitionId)

    if (localTriggerDefinition && !remoteTriggerDefinition) {
      // If the remote trigger definition has been deleted, delete the local one too
      this.triggerDefinitionsCollection.delete(localTriggerDefinition.id)
    } else if (localTriggerDefinition && remoteTriggerDefinition) {
      // If both local and remote exist, update local trigger definition with remote data
      localTriggerDefinition.deserialize(remoteTriggerDefinition)
    } else if (!localTriggerDefinition && remoteTriggerDefinition) {
      // If the trigger definition exists only on remote, add it to the local collection
      this.triggerDefinitionsCollection.put(
        new WorkflowTriggerDefinitionModel(remoteTriggerDefinition),
      )
    }
  }

  private async fetchDefinitionsForEntityId(entityId: string) {
    const response = await this.root.transport.workflow.definitions.list({
      orgId: this.root.organization.getCurrentOrganization().id,
      userId: this.root.user.getCurrentUser().id,
      maxResults: 10000,
      entityIds: [entityId],
    })

    return response.data
  }

  private async fetchStepDefinitionById(id: string) {
    const response = await this.root.transport.workflow.stepDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private async fetchTriggerDefinitionById(id: string) {
    const response = await this.root.transport.workflow.triggerDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private load() {
    return Promise.all([
      this.definitionsCollection.performQuery((repo) => repo.all()),
      this.stepDefinitionsCollection.performQuery((repo) => repo.all()),
      this.triggerDefinitionsCollection.performQuery((repo) => repo.all()),
    ])
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'ring-order-update': {
          // TODO placeholder
          break
        }
      }
    })
  }
}
