import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model/base'

export type RawWorkflowStepDefinition = DBTypes.WorkflowStepDefinitionRow

export default class WorkflowStepDefinitionModel implements Model {
  private raw: RawWorkflowStepDefinition

  get id(): string {
    return this.raw.id
  }

  get requiredConfiguration() {
    return this.raw.requiredConfiguration
  }

  constructor(attrs: MarkOptional<RawWorkflowStepDefinition, 'id'>) {
    this.raw = {
      ...attrs,
      id: attrs.id ?? `WSD${uuid()}`.replace(/-/g, ''),
    }

    makeAutoObservable<this>(this)
  }

  deserialize(json: RawWorkflowStepDefinition): this {
    this.raw = json
    return this
  }

  serialize(): RawWorkflowStepDefinition {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawWorkflowStepDefinition>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
